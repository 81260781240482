import React, { useState } from 'react';
import { Typography, Box, Fab } from '@mui/material';
import { SimulatorCard } from './SimulatorCard';
import SimulationResultsModal from './SimulationResultsModal';
import { AddCircle, PlayCircle } from '@mui/icons-material';
import { SimulatorCardData } from './types';
import { generateDateRange } from '../../../functions/simulationFunction';

const initialCardData = (id: number, copyData: SimulatorCardData | null): SimulatorCardData => {

    if (copyData) {
        return { ...copyData, id };
    }
    return {
        id,
        machineHashRate: 0,
        machinePower: 0,
        machinePrice: 0,
        hostingRate: 0.06,
        hostingBillCutoffDay: '0',
        uptimeGuarantee: '100',
        expenseRevenue: '',
        expenseRevenueDoller: '',
        difficultyIncreaseMonthly: 2,
        coinPriceIncrease: 2,
        coinCurrentPrice: 0,
        hostingRevenueAdvance: 0,
    };
};

const SimulatorControll: React.FC = () => {
    const [simulators, setSimulators] = useState<SimulatorCardData[]>([
        initialCardData(1, null)
    ]);
    const [simulationResults, setSimulationResults] = useState<any[]>([]);
    const [isModalOpen, setModalOpen] = useState(false);

    const handleAddSimulator = () => {
        const newSimulator = initialCardData(
            simulators.length + 1,
            simulators.length > 0 ? simulators[simulators.length - 1] : null
        );
        setSimulators([...simulators, newSimulator]);
    };

    const handleUpdateSimulator = (updatedData: SimulatorCardData) => {
        setSimulators((prevSimulators) =>
            prevSimulators.map((sim) => (sim.id === updatedData.id ? updatedData : sim))
        );
    };

    const handleRemoveSimulator = (id: number) => {
        setSimulators((prevSimulators) => prevSimulators.filter((sim) => sim.id !== id));
    };

    const executeSimulation = () => {
        const results = simulators.map((simulator) => {

            const date = new Date()
            
            const { breakEvenDays,
                breakEvenDate,
                treasuryBalance,
                treasuryBalanceInUSD,
                currentCoinPrice
            } = generateDateRange(simulator, date);


            


            return {
                simulatorId: simulator.id,
                breakEvenDays,
                breakEvenDate,
                treasuryBalance,
                treasuryBalanceInUSD,
                currentCoinPrice,
            };
        });
        
        setSimulationResults(results);
        setModalOpen(true);
    };

    return (
        <div style={{ padding: '16px' }}>
            <Box
                display="flex"
                alignItems="center"
                position="sticky"
                top="70px"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                gap={1}
                zIndex={100}
                sx={{
                    mb: 2,
                    backgroundColor: '#d1e8ff',
                    padding: { xs: 1, sm: 1.5 },
                    borderRadius: 1,
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        fontFamily: 'monospace',
                        ml: { xs: 0, sm: '1rem' },
                        color: '#1565c0',
                        textAlign: { xs: 'center', sm: 'left' },
                        width: '100%',
                    }}
                    gutterBottom
                >
                    SIMULATOR
                </Typography>
                <Box
                    display="flex"
                    gap={1}
                    width="100%"
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                >
                    <Fab
                        variant="extended"
                        size="medium"
                        onClick={handleAddSimulator}
                        color="primary"
                        aria-label="add"
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                            typography: { xs: 'caption', sm: 'button' },
                        }}
                    >
                        <AddCircle sx={{ mr: 1 }} />
                        <Typography
                            sx={{
                                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                            }}
                        >
                            Add New
                        </Typography>
                    </Fab>
                    <Fab
                        variant="extended"
                        size="medium"
                        onClick={executeSimulation}
                        color="primary"
                        sx={{
                            width: { xs: '100%', sm: 'auto' },
                            typography: { xs: 'caption', sm: 'button' },
                        }}
                    >
                        <PlayCircle sx={{ mr: 1 }} />
                        <Typography
                            sx={{
                                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                            }}
                        >
                            Execute
                        </Typography>
                    </Fab>

                </Box>
            </Box>

            {simulators.map((simulator) => (
                <SimulatorCard
                    key={simulator.id}
                    data={simulator}
                    onUpdate={handleUpdateSimulator}
                    onClose={handleRemoveSimulator}
                />
            ))}


            <SimulationResultsModal
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                results={simulationResults}
            />
        </div>
    );
};


export default SimulatorControll;
