import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Fab, Box, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Download, Close } from '@mui/icons-material';
import { ExtendedDateEntry } from './types';

interface ExecutionResultTableProps {
    resultTableData: ExtendedDateEntry[];
    setTreasuryCutOffDay: number;
    handleDownloadCSV: () => void;
    onClose: () => void;
}

const ExecutionResultTable: React.FC<ExecutionResultTableProps> = ({
    resultTableData,
    setTreasuryCutOffDay,
    handleDownloadCSV,
    onClose,
}) => {
    return (
        <>
            <Box display="flex" justifyContent="space-between">
                <DialogTitle sx={{ pb: 0, mb: 0 }}>Execution Result</DialogTitle>
                <Box>
                    <Fab
                        size="small"
                        onClick={handleDownloadCSV}
                        color="primary"
                        aria-label="download"
                        sx={{ mr: 1, mt: 2 }}
                    >
                        <Download />
                    </Fab>
                    <Fab
                        size="small"
                        onClick={onClose}
                        color="primary"
                        aria-label="close"
                        sx={{ mr: 2, mt: 2 }}
                    >
                        <Close />
                    </Fab>
                </Box>
            </Box>
            <DialogContent>
                <Table>
                    <TableHead style={{ backgroundColor: '#d1e8ff', position: 'sticky', top: -25, zIndex: 1 }}>
                        <TableRow>
                            <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '8px', textAlign: 'center' }}>Date</TableCell>
                            <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '8px', textAlign: 'center' }}>Production in Coin (BTC)</TableCell>
                            <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '8px', textAlign: 'center' }}>Production in USD</TableCell>
                            <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '8px', textAlign: 'center' }}>Electricity Expense ($)</TableCell>
                            <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '8px', textAlign: 'center' }}>Miner Profit ($)</TableCell>
                            <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '8px', textAlign: 'center' }}>Coin Balance in Treasury (BTC)</TableCell>
                            <TableCell style={{ color: '#000', fontWeight: 'bold', fontSize: 'small', padding: '8px', textAlign: 'center' }}>Current Coin Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resultTableData.map((row, index) => (
                            <TableRow
                                key={index}
                                style={{
                                    backgroundColor: setTreasuryCutOffDay === index + 1 ? 'lightcoral' : 'inherit',
                                }}
                            >
                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{row.date}</TableCell>
                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{row.productionInCoin.toFixed(8)}</TableCell>
                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{row.productionInUSD.toFixed(4)}</TableCell>
                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{row.electricityExpense.toFixed(4)}</TableCell>
                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{row.minerProfit.toFixed(4)}</TableCell>
                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff',fontSize: 'small',textAlign: 'center'}}>{row.coinBalance.toFixed(8)}</TableCell>
                                <TableCell sx={{ color: (theme) => theme.palette.mode === 'light' ? '#757575' : '#fff', fontSize: 'small', textAlign: 'center' }}>{row.currentCoinPrice.toFixed(4)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </>
    );
};

export default ExecutionResultTable;
