import React, { useState } from 'react';
import {Card,CardContent,CardHeader,Grid,TextField,Typography,Button,Box,InputAdornment} from '@mui/material';
import ExecutionModal from './ExecutionModal';
import { TuneSharp } from '@mui/icons-material';
import { SimulatorCardData } from './types';


export const SimulatorCard: React.FC<{
    data: SimulatorCardData;
    onUpdate: (updatedData: SimulatorCardData) => void;
    onClose: (id: number) => void;
}> = ({ data, onUpdate, onClose }) => {

    const [isFocused, setIsFocused] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleExecute = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    const handleChange = (field: keyof SimulatorCardData) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        let value = event.target.value;
        if (field === 'expenseRevenueDoller') {
            const updatedExpenseRevenue = value ? Number(value) / data.coinCurrentPrice : 0;
            onUpdate({ ...data, expenseRevenueDoller: value, expenseRevenue: updatedExpenseRevenue.toString() });
        } else if (field === 'expenseRevenue') {
            const updatedExpenseRevenueDoller = value ? data.coinCurrentPrice * Number(value) : 0;
            onUpdate({ ...data, expenseRevenue: value, expenseRevenueDoller: updatedExpenseRevenueDoller.toString() });
        } else if (field === 'coinCurrentPrice') {
            const updatedExpenseRevenueDoller = data.expenseRevenue ? Number(data.expenseRevenue) * Number(value) : 0;
            const updatedExpenseRevenue = value ? Number(data.expenseRevenueDoller) / Number(value) : 0;
            onUpdate({
                ...data,
                coinCurrentPrice: Number(value),
                expenseRevenue: updatedExpenseRevenue.toString(),
                expenseRevenueDoller: updatedExpenseRevenueDoller.toString(),
            });
        } else {
            if (field === 'hostingBillCutoffDay') {
                if (!isNaN(Number(value)) || value === '') {
                    value = isFocused ? value : value ? `${value}th day of Month` : '';
                }
            }
            onUpdate({ ...data, [field]: value });
        }
    };


    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };


    return (
        <Card sx={{
            margin: '16px 0',
            padding: '8px',
            minHeight: '100px',
            boxShadow: 3,
            backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#0f0f0f' : '#f6f6f6',
        }}>
            <CardContent sx={{ padding: '8px' }}>
                <Grid container spacing={1} >
                    <Grid item xs={12} md={6}>
                        <CardHeader
                            avatar={
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        color: "#1565c0"
                                    }}
                                >
                                    <TuneSharp fontSize="small" />
                                </Box>
                            }
                            title={`SIMULATION ${data.id}`}
                            sx={{
                                padding: '12px',
                                '& .MuiCardHeader-title': {
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    textAlign: 'left',
                                    color: "#1565c0"
                                },
                            }}
                        />

                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField
                            variant="filled"
                            fullWidth
                            size="small"
                            label="Machine Hash Rate (TH/s)"
                            value={data.machineHashRate}
                            onChange={handleChange('machineHashRate')}
                            focused
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField
                            variant="filled"
                            fullWidth
                            size="small"
                            label="Machine Power (W)"
                            value={data.machinePower}
                            onChange={handleChange('machinePower')}
                            focused
                        />
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: '16px', justifyContent: 'center', borderTop: '1px dotted #1565c075', mx: 'auto' }}>
                        <Grid container item xs={12} spacing={1} direction="row" justifyContent="center">
                            <Grid item sx={{
                                flex: {
                                    xs: '1 1 100%',
                                    sm: '1 1 48%',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        marginBottom: '16px',
                                        fontSize: '0.85rem',
                                        fontWeight: 600,
                                        color: "#1565c0"
                                    }}
                                >
                                    Price & Hosting
                                </Typography>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Machine Price ($)"
                                            type="number"
                                            value={data.machinePrice}
                                            onChange={handleChange('machinePrice')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Hosting Rate ($)"
                                            type="number"
                                            value={data.hostingRate}
                                            onChange={handleChange('hostingRate')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Hosting Bill Cutoff Day"
                                            value={data.hostingBillCutoffDay}
                                            onChange={handleChange('hostingBillCutoffDay')}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            InputProps={{
                                                endAdornment: !isFocused && data.hostingBillCutoffDay ? (
                                                    <InputAdornment position="end">
                                                        <small style={{ color: "#1565c0" }}>th day of Month</small>
                                                    </InputAdornment>
                                                ) : null,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Uptime Guarantee (%)"
                                            value={data.uptimeGuarantee}
                                            onChange={handleChange('uptimeGuarantee')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{
                                flex: '1 1 2%',
                                display: {
                                    xs: 'none',
                                    md: 'flex',
                                },
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                            }}>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        width: '2px',
                                        borderLeft: '1px dotted #1565c075',
                                    }} />
                            </Grid>


                            <Grid item sx={{ flex: '1 1 48%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        marginBottom: '16px',
                                        fontSize: '0.85rem',
                                        fontWeight: 600,
                                        color: "#1565c0"
                                    }}
                                >
                                    External Variables
                                </Typography>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Coin Current Price ($)"
                                            type="number"
                                            value={data.coinCurrentPrice}
                                            onChange={handleChange('coinCurrentPrice')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Coin Price Increase Monthly(%)"
                                            type="number"
                                            value={data.coinPriceIncrease}
                                            onChange={handleChange('coinPriceIncrease')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Expense (Revenue) of Mining (coin)"
                                            type="number"
                                            value={data.expenseRevenue}
                                            onChange={handleChange('expenseRevenue')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Expense (Revenue) of Mining ($)"
                                            type="number"
                                            value={data.expenseRevenueDoller}
                                            onChange={handleChange('expenseRevenueDoller')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Difficulty Increase Monthly (%)"
                                            type="number"
                                            value={data.difficultyIncreaseMonthly}
                                            onChange={handleChange('difficultyIncreaseMonthly')}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Hosting Revenue Advance in Days"
                                            type="number"
                                            value={data.hostingRevenueAdvance}
                                            onChange={handleChange('hostingRevenueAdvance')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '8px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography sx={{ fontSize: '0.85rem' }}>
                                    <span style={{ color: "gray" }}>Total Initial Cash:</span><strong style={{ color: "#1565c0" }}>${' '}
                                        {(
                                            Number(data.machinePrice) +
                                            data.hostingRevenueAdvance * (data.hostingRate * 3 * 24)
                                        )}
                                    </strong>
                                </Typography>
                                <Typography sx={{ fontSize: '0.85rem' }}>
                                    <span style={{ color: "gray" }}>Total Coin Purchase (Treasury):</span><strong style={{ color: "#1565c0" }}>{' '}
                                        {(
                                            (((Number(data.machinePrice) +
                                                (data.hostingRevenueAdvance * (data.hostingRate * 3 * 24)))) /
                                            data.coinCurrentPrice).toFixed(8)
                                        )}{' '}
                                        coins
                                    </strong>
                                </Typography>
                            </Box>
                            <Box display="flex" gap={1}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleExecute}
                                >
                                    Execute
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => onClose(data.id)}
                                >
                                    Close
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
            <ExecutionModal open={isModalOpen} onClose={handleCloseModal} data={data} />
        </Card >
    );
};