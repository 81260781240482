import { ExtendedDateEntry, SimulatorCardData, TreasuryLoseData } from "../pages/Components/Simulator/types";
import { calculateElectricityExpense, calculateProductionInCoin, calculateTotalInitialCash, formatDateToDDMMYYYY } from "../pages/Components/Simulator/utils";


export const generateDateRange = (data: SimulatorCardData, date: Date) => {

    const startDate = new Date(date.getTime());
    const coinPriceIncrease = data.coinPriceIncrease / 100;
    const difficultyIncrease = data.difficultyIncreaseMonthly / 100;
    const expenseRevenue = Number(data.expenseRevenue) * (Number(data.uptimeGuarantee) / 100);
    const totalInitialCash = calculateTotalInitialCash(data);
    const totalCoinPurchase = totalInitialCash / data.coinCurrentPrice;

    let treasuryLoseData: TreasuryLoseData = {
        days: 0,
        cutOffDate: date,
        treasuryBalance: 0,
        treasuryBalanceInUSD: 0,
        currentCoinPrice: 0,
    };

    const datas: ExtendedDateEntry[] = [];

    let currentDate = date;
    let coinBalance = totalCoinPurchase;
    let currentCoinPrice = data.coinCurrentPrice;
    let productionInCoin = 0;
    let productionInUSD = 0;
    let minerProfit = 1;
    let isFirstMonth = true;

    let minCoinBalance = 0;
    let minCoinBalanceDate;
    let minCoinBalanceDay = 0;
    let minCoinCurrentprice = 0;

    let prevCoinBalance = 0;

    let day = 0;
    const maxDays = 1825;

    while (minerProfit > 0 && day < maxDays) {

        prevCoinBalance = currentCoinPrice;

        if (currentDate.getDate() === 1) {
            currentCoinPrice *= 1 + coinPriceIncrease;
        }

        if (day === 0 || currentDate.getDate() === 1) {
            productionInCoin = calculateProductionInCoin(day, expenseRevenue, productionInCoin, difficultyIncrease);
            productionInUSD = productionInCoin * currentCoinPrice;
        }

        if (Number(data.hostingBillCutoffDay) === currentDate.getDate()) {
            treasuryLoseData = {
                days: day,
                cutOffDate: currentDate,
                treasuryBalance: coinBalance,
                treasuryBalanceInUSD: coinBalance * currentCoinPrice,
                currentCoinPrice: currentCoinPrice,
            };

            const dailyHostingCharge =
                (data.hostingRate * (data.machinePower / 1000) * 24) / currentCoinPrice;

            if (isFirstMonth) {
                
                if (Number(data.hostingBillCutoffDay) > startDate.getDate()) {
                    const daysToCutoff = Number(data.hostingBillCutoffDay) - startDate.getDate();
                    const hostingCharge = (daysToCutoff) * dailyHostingCharge;
                    coinBalance += hostingCharge;
                } else if (Number(data.hostingBillCutoffDay) < startDate.getDate()) {
                    const daysToNextCutoff = 30 - startDate.getDate();
                    const hostingCharge = daysToNextCutoff * dailyHostingCharge;
                    coinBalance += hostingCharge;
                } else {
                    const hostingCharge = 0;
                    coinBalance += hostingCharge;
                }
                isFirstMonth = false;
            } else {
                const hostingCharge = dailyHostingCharge * 30;
                coinBalance += hostingCharge;
            }
        }

        const electricityExpense = calculateElectricityExpense(data);
        minerProfit = productionInUSD - electricityExpense;

        coinBalance -= productionInCoin;

        if (minCoinBalance > coinBalance) {
            minCoinBalance = coinBalance;
            minCoinBalanceDate = formatDateToDDMMYYYY(currentDate.toDateString());
            minCoinBalanceDay = day;
            minCoinCurrentprice = currentCoinPrice;
        }

        datas.push({
            date: formatDateToDDMMYYYY(currentDate),
            productionInCoin,
            productionInUSD,
            electricityExpense,
            minerProfit,
            coinBalance,
            minCoinBalance,
            minCoinBalanceDate,
            minCoinBalanceDay,
            currentCoinPrice,
        });

        currentDate.setDate(currentDate.getDate() + 1);
        day++;
    }

    const breakEvenDaysTemp = day >= maxDays ? 'N/A' : (treasuryLoseData.days ? treasuryLoseData.days - 1 : day - 1);
    const breakEvenDays = minCoinBalanceDay === 0 ? breakEvenDaysTemp : minCoinBalanceDay < Number(breakEvenDaysTemp) ? minCoinBalanceDay : breakEvenDaysTemp;

    const treasuryBalanceTemp = (treasuryLoseData.treasuryBalance ? treasuryLoseData.treasuryBalance : coinBalance);
    const treasuryBalance = minCoinBalanceDay === 0 ? treasuryBalanceTemp : minCoinBalanceDay < Number(breakEvenDaysTemp) ? minCoinBalance : treasuryBalanceTemp;

    const minCoinBalanceInUSD = minCoinBalance * minCoinCurrentprice;

    const treasuryBalanceInUSDTemp = (treasuryLoseData.treasuryBalance ? treasuryLoseData.treasuryBalance : coinBalance) * prevCoinBalance;
    const treasuryBalanceInUSD = minCoinBalanceDay === 0 ? treasuryBalanceInUSDTemp : minCoinBalanceDay < Number(breakEvenDaysTemp) ? minCoinBalanceInUSD : treasuryBalanceInUSDTemp;
    
    const breakEvenDate = breakEvenDays !== 'N/A'
        ? formatDateToDDMMYYYY(new Date(startDate.getTime() + Number(breakEvenDays) * 24 * 60 * 60 * 1000))
        : 'N/A';

    return {
        datas,
        breakEvenDays,
        breakEvenDate,
        treasuryBalance,
        treasuryBalanceInUSD,
        currentCoinPrice: minCoinCurrentprice ? minCoinCurrentprice : prevCoinBalance,
    };
};
