import React, { useEffect, useState } from 'react';
import { Download, Search } from '@mui/icons-material';
import { Autocomplete, Button, Grid, MenuItem, Select, SelectChangeEvent, TextField, Box, Pagination, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';
import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import { CustomerGroup, MachineModelInfo, SelectedDataItem } from '../Machines/types';
import SortableTable from './WorkerTable';
import SearchFilters from './WorkerSearchFilterBar';
import PaginationControls from '../GlobalComponents/Pagination';

export interface WorkerData {
    workerId: string;
    expenseInDollar?: number;
    machineId?: string | null;
    machineConfig?: string | null;
    machineModelName?: string | null;
    currency?: string;
    spend?: number;
    expenseDate?: string;
    workerEngine?: string;
    uptimeInHours?: number;
    hostingRate?: string | null;
    power?: string | null;
    totalCharge?: string | null;
    customerId?: string | null;
}

interface WorkerProps {
    fromDate: Date | null;
    setFromDate: (date: Date | null) => void;
    toDate: Date | null;
    setToDate: (date: Date | null) => void;
    searchTrigger: boolean;
}

interface SearchOption {
    id: number;
    name: string;
}

const WorkerData: React.FC<WorkerProps> = ({ fromDate, setFromDate, toDate, setToDate, searchTrigger }) => {
    const [selectedPool, setSelectedPool] = useState<string>('ALL');
    const [searchMachineOptions, setSearchMachineOptions] = useState<SearchOption[]>([]);
    const [searchMachineTerm, setSearchMachineTerm] = useState<number[]>([]);
    const [searchGroupTerm, setSearchGroupTerm] = useState<string[]>([]);
    const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);
    const [searchTerm, setSearchTerm] = useState<number[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [tableLoader, setTableLoader] = useState(false);
    const [searchKeyInput, setSearchKeyInput] = useState<string>('');
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        totalPages: 0,
    });
    const [page, setPage] = useState(1)
    const [orderBy, setOrderBy] = useState<string>();
    const [order, setOrder] = useState<'ASC' | 'DESC'>();
    const [tableTotal, setTableTotal] = useState({
        hostingRate: '0',
        power: '0',
        spend: '0',
        totalCharge: '0',
        total_records: '0',
        uptimeInHours: '0',
    })
    const [btcPrice, setBtcPrice] = useState(0)

    useEffect(() => {
        fetchCustomerList();
        fetchMachineList();
    }, []);

    useEffect(() => {
        const fromTime = formatDate(fromDate);
        const toTime = formatDate(toDate);
        fetchWorkerData(fromTime, toTime, searchTerm, searchMachineTerm);
    }, [fromDate, toDate, searchTerm, searchMachineTerm, pagination.pageIndex, rowsPerPage, orderBy, order, searchGroupTerm, searchTrigger]);

    const fetchCustomerList = async () => {
        try {
            const response = await axios.get(`${API_PATH}/customer/list?orderBy=none`, getAxiosConfig());
            const dataList = response.data.map((item: CustomerGroup) => ({
                id: item.id,
                name: item.customerGroupName,
            }));
            dataList.unshift({ id: -1, name: 'unknown' });
            setSearchOptions(dataList);
        } catch (error) {
            console.error('Error fetching customer list:', error);
        }
    };

    const fetchMachineList = async () => {
        try {
            const response = await axios.get(`${API_PATH}/machine_model/list`, getAxiosConfig());
            const dataList = response.data.map((item: MachineModelInfo) => ({
                id: item.id,
                name: item.modelName,
            }));
            dataList.unshift({ id: -1, name: 'unknown' });
            setSearchMachineOptions(dataList);
        } catch (error) {
            console.error('Error fetching machine list:', error);
        }
    };

    const formatDate = (date: Date | null): string => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const fetchWorkerData = (fromTime: string, toTime: string, customerIds: number[], modelIds: number[]) => {
        setTableLoader(true);
        axios.post(`${API_PATH}/finance/report/worker`,
            {
                customerIds,
                modelIds,
                fromTime,
                toTime,
                host: selectedPool,
                searchKey: searchKeyInput,
                page: page,
                size: rowsPerPage,
                sort: orderBy ? { [orderBy]: order } : {},
                groupBy: searchGroupTerm
            }, getAxiosConfig())
            .then((response) => {
                const customerList = searchOptions;
                const updatedRows = response.data.data.map((row: any) => {
                    let customerName = "unknown";
                    let machineModelName = row.machineModelName;
                    if (row.customerId !== null && row.customerId !== 0 && row.customerId !== -1) {
                        const customer = customerList.find((customer) => customer.id === row.customerId);
                        customerName = customer ? customer.name : "unknown";
                    }
                    if (row.machineModelName === null) {
                        machineModelName = "unknown";
                    }
                    return {
                        ...row,
                        machineModelName,
                        customer: customerName,
                    };
                });

                setRows(updatedRows);
                setTableTotal(response.data.total);
                setBtcPrice(response.data.btc)


                setPagination({
                    pageIndex: response.data.currentPage - 1,
                    totalPages: response.data.totalPages,
                });
                setTableLoader(false);
            })
            .catch((error) => {
                console.error('Error fetching worker data:', error);
            })
            .finally(() => {
                setTableLoader(false);
            });
    };


    const handleSearch = () => {
        const fromTime = formatDate(fromDate);
        const toTime = formatDate(toDate);
        fetchWorkerData(fromTime, toTime, searchTerm, searchMachineTerm);
    };

    const handlePoolChange = (event: SelectChangeEvent<string>) => {
        setSelectedPool(event.target.value);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
        setPagination((prev) => ({
            ...prev,
            pageIndex: value - 1,
        }));
    };

    const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
        const newRowsPerPage = Number(event.target.value);
        setPage(1)
        setRowsPerPage(newRowsPerPage);
        setPagination((prev) => ({
            ...prev,
            pageIndex: 0,
        }));
    };

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'ASC';
        setOrderBy(property);
        setOrder(isAsc ? 'DESC' : 'ASC');
    };

    const handleDownloadCSV = () => {
        const headers = [
            'Expense Date',
            'Worker ID',
            'Customer',
            'Machine Model',
            'Coin',
            'Uptime (hrs)',
            'Power',
            'Hosting Rate',
            'Worker Engine',
            'Expense(Btc)',
            'Expense($)',
            'Income($)',
            'P/L',];
        const csvRows = [headers.join(','),
        ...rows.map(row => [
            row.expenseDate,
            row.workerId,
            row.customer,
            row.machineModelName,
            row.currency,
            row.uptimeInHours,
            row.power,
            row.hostingRate,
            row.workerEngine,
            row.spend?.toFixed(8),
            row.expenseInDollar?.toFixed(2),
            Number(row.totalCharge).toFixed(2),
            (Number(row.totalCharge) - row.expenseInDollar).toFixed(2)
        ].join(',')),
        ];

        const totalRow = [
            'Total',
            '', '', '', '', '', '', '', '',
            Number(tableTotal.spend).toFixed(5),
            (Number(tableTotal.spend) * btcPrice).toFixed(2),
            Number(tableTotal.totalCharge).toFixed(5),
            (Number(tableTotal.totalCharge) - (Number(tableTotal.spend) * btcPrice)).toFixed(2),
        ].join(',');
        csvRows.push(totalRow);

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `worker_report.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <SearchFilters
                selectedPool={selectedPool}
                handlePoolChange={handlePoolChange}
                searchOptions={searchOptions}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchMachineOptions={searchMachineOptions}
                searchMachineTerm={searchMachineTerm}
                setSearchMachineTerm={setSearchMachineTerm}
                searchGroupTerm={searchGroupTerm}
                setSearchGroupTerm={setSearchGroupTerm}
                searchKeyInput={searchKeyInput}
                setSearchKeyInput={setSearchKeyInput}
                handleSearch={handleSearch}
                handleDownloadCSV={handleDownloadCSV}
            />
            <SortableTable
                rows={rows}
                order={order || undefined}
                orderBy={orderBy || undefined}
                handleRequestSort={handleRequestSort}
                tableTotal={tableTotal}
                btc={btcPrice}
                isLoading={tableLoader}

            />
            <PaginationControls
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                totalPages={pagination.totalPages}
                currentPage={pagination.pageIndex + 1}
                onPageChange={handlePageChange}
            />

        </>
    );
};

export default WorkerData;
